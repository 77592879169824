import React from 'react';
import './style/index.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./Pages/Home";
import ContactPage from "./Pages/Contact";
import Layout from "./Pages/layaout";
import AboutUsPage from "./Pages/AboutUs";


function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/contact" element={<ContactPage/>}/>
          <Route path="/aboutus" element={<AboutUsPage/>}/>
          <Route path="/subscribe" element={<>subscribe</>}/>
          <Route path="/events" element={<>events</>}/>
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
