import Logo from '../../Images/logo/logo_without_text.png';
import {Link, useLocation, useNavigation} from "react-router-dom";

export default function HomePage({page}:any) {
  const location = useLocation();
  return (
      <header>
        <h1><img src={Logo}/></h1>
        <nav>
          <ul>
            {genLinks(location.pathname)}
          </ul>
        </nav>
      </header>
  )
}

const links = [
  {label: "Accueil", path: "/"},
  {label: "A propos de nous", path: "/aboutus"},
  {label: "Évènements", path: "/events"},
  {label: "Nous Contacter", path: "/contact"},
]

function genLinks(locPath:string) {
  return links.map((v) => <li><Link to={v.path} className={locPath === v.path?"active":""} >{v.label}</Link></li>)
}
