import img_1 from '../../Images/bullying/story_1.png';
import img_2 from '../../Images/bullying/story_2.png';
import img_3 from '../../Images/bullying/story_3.png';
import img_sologne from '../../Images/home/Blason-Sologne.png';
import Hero from "../../Components/Hero";
import Hlist from "../../Components/Hlist";
import data from "../../Data/home_hlist";


export default function HomePage() {
  return (
    <>
      <Hero/>
      <div className="home_container first">
        <img src={img_1} alt=""/>
        <p>L’association a pour objet la sensibilisation, la prévention, la lutte contre le harcèlement scolaire. Nos
          actions sont diverses et variées, telles que des interventions dans les établissements scolaires (école,
          collège, lycée), des conférences à grande échelle pour tout public, des permanences ouvertes à toutes
          personnes ayant besoin d’informations, de soutien, de conseils, ou d’informations générales pour lutter contre
          ce fléau.</p>
      </div>
      <div className="home_container">
        <img src={img_3} alt=""/>
        <p>spitch harcelement scolaire</p>
      </div>
      <div className="home_container">
        <img src={img_2} alt=""/>
        <p>spitch cyber-harcelement</p>
      </div>
      <div className="confiance">
        <h2>Ils nous font confiance</h2>
        <Hlist list={data}/>
      </div>
    </>
  )
}
