import {useState} from "react";

export default function HlistItem(props: any) {
  const [open, setOpen] = useState(false);
  let {img, name, msg, poste, img_por} = props;
  return (
    <div className="item">
      <div>
        <img className={img_por ? 'portrait' : ''} src={img}/>
        <span className="name">{name}</span>
        <span className="poste">{poste}</span>
      </div>
    </div>
  )
}
