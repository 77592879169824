import Header from "../Components/Header";
import Wrapper from "../Components/Wrapper";
import Footer from "../Components/Footer";

export default function Layout({children}: any) {
  return (
    <>
      <Header/>
      <Wrapper>
        {children}
      </Wrapper>
      <Footer/>
    </>
  )
}
