import img_sologne from "../Images/home/Blason-Sologne.png";
import img_p_1 from "../Images/person/1.jpg";
import img_p_2 from "../Images/person/2.jpg";
import img_p_3 from "../Images/person/3.jpg";
import img_p_4 from "../Images/person/4.jpg";
import img_p_5 from "../Images/person/5.jpg";


const data = [
  {
    name: "Stella COCHETON",
    poste: "Maire de la Ville de Selles-sur-Cher",
    msg: "Nous avons décidé de collaborer avec l’association LCLHSS41, l’association a décider de choisir la ville de Selles-sur-Cher comme ville pilote, du fait de ces infrastructures scolaires et de son investissement auprès des jeunes Sellois, il nous est apparu important voir primordial d’encourager une telle initiative.\nNous avons également depuis peu un bureau à Selles-sur-Cher et une antenne en Sologne.",
    img: img_sologne,
    img_por: false
  },
  {
    name: "Angélique DUBÉ",
    poste: "Adjointe aux affaires scolaires, et conseillère départementale",
    msg: "En qualité de maire adjointe, je suis partenaire de l’association Luttons Contre Le Harcèlement Scolaire SUD 41 car il est pour moi indispensable de sensibiliser les familles, les élèves, mais également les équipes pédagogiques au  harcèlement au cyberharcèlement à l’école, en tant qu’enseignante moi même, j’approuve les actions mises en place par l’association qui sont des actions réfléchies, étudiées. La prise de conscience collective des ces phénomènes, et l’implication de tous pour enrayer ce problème.",
    img: img_p_1,
    img_por: true
  },
  {
    name: "Angélique DUBÉ",
    poste: "Adjointe aux affaires scolaires, et conseillère départementale",
    msg: "En qualité de maire adjointe, je suis partenaire de l’association Luttons Contre Le Harcèlement Scolaire SUD 41 car il est pour moi indispensable de sensibiliser les familles, les élèves, mais également les équipes pédagogiques au  harcèlement au cyberharcèlement à l’école, en tant qu’enseignante moi même, j’approuve les actions mises en place par l’association qui sont des actions réfléchies, étudiées. La prise de conscience collective des ces phénomènes, et l’implication de tous pour enrayer ce problème.",
    img: img_p_2,
    img_por: true
  },
  {
    name: "Angélique DUBÉ",
    poste: "Adjointe aux affaires scolaires, et conseillère départementale",
    msg: "En qualité de maire adjointe, je suis partenaire de l’association Luttons Contre Le Harcèlement Scolaire SUD 41 car il est pour moi indispensable de sensibiliser les familles, les élèves, mais également les équipes pédagogiques au  harcèlement au cyberharcèlement à l’école, en tant qu’enseignante moi même, j’approuve les actions mises en place par l’association qui sont des actions réfléchies, étudiées. La prise de conscience collective des ces phénomènes, et l’implication de tous pour enrayer ce problème.",
    img: img_p_3,
    img_por: true
  },
  {
    name: "Angélique DUBÉ",
    poste: "Adjointe aux affaires scolaires, et conseillère départementale",
    msg: "En qualité de maire adjointe, je suis partenaire de l’association Luttons Contre Le Harcèlement Scolaire SUD 41 car il est pour moi indispensable de sensibiliser les familles, les élèves, mais également les équipes pédagogiques au  harcèlement au cyberharcèlement à l’école, en tant qu’enseignante moi même, j’approuve les actions mises en place par l’association qui sont des actions réfléchies, étudiées. La prise de conscience collective des ces phénomènes, et l’implication de tous pour enrayer ce problème.",
    img: img_p_4,
    img_por: true
  },
  {
    name: "Angélique DUBÉ",
    poste: "Adjointe aux affaires scolaires, et conseillère départementale",
    msg: "En qualité de maire adjointe, je suis partenaire de l’association Luttons Contre Le Harcèlement Scolaire SUD 41 car il est pour moi indispensable de sensibiliser les familles, les élèves, mais également les équipes pédagogiques au  harcèlement au cyberharcèlement à l’école, en tant qu’enseignante moi même, j’approuve les actions mises en place par l’association qui sont des actions réfléchies, étudiées. La prise de conscience collective des ces phénomènes, et l’implication de tous pour enrayer ce problème.",
    img: img_p_5,
    img_por: true
  },
]

export default data;
