import React from "react";


import img_1 from '../../Images/hero/img_1.jpg'
import img_2 from '../../Images/hero/img_2.jpg'
import img_3 from '../../Images/hero/img_3.jpg'
import img_4 from '../../Images/hero/img_4.jpg'
import img_5 from '../../Images/hero/img_5.jpg'

export default class Hero extends React.Component {
  tickInterval: any;

  constructor(props: any) {
    super(props);
    this.state = {
      index: 0,
      tab: [img_1, img_2, img_3, img_4, img_5]
    }

    this.tick = this.tick.bind(this);
  }

  componentDidMount() {
    this.tickInterval = setInterval(this.tick, 10000)
  }

  componentWillUnmount() {
    clearInterval(this.tickInterval);
  }

  render() {
    return (
      <div className="hero">
        {/* @ts-ignore */}
        <img src={this.state.tab[this.state.index]} alt=""/>
      </div>
    )
  }

  tick() {
    console.log("tick")
    // @ts-ignore
    if(this.state.index === 4) {
      this.setState({index:0})
    } else {
      // @ts-ignore
      this.setState({index: this.state.index+1})
    }
  }
}
