import HlistItem from "./item";

export default function Hlist(props: any) {
  const {list} = props
  return (
    <div className="hlist">
      {
        list.map((v:any) => <HlistItem name={v.name} poste={v.poste} msg={v.msg} img={v.img} img_por={v.img_por}/>)
      }
    </div>
  )
}
