import img_1 from '../../Images/bullying/story_1.png';
import img_2 from '../../Images/bullying/story_2.png';
import img_3 from '../../Images/bullying/story_3.png';
import img_hero from '../../Images/hero/img_1.jpg';
import img_sologne from '../../Images/home/Blason-Sologne.png';
import Hlist from "../../Components/Hlist";
import data from "../../Data/home_hlist";

export default function AboutUsPage() {
  return (
    <>
      <div className="hero">
        <img src={img_hero}/>
      </div>
      <div className="home_container first">
        <img src={img_1} alt=""/>
        <p>description 1</p>
      </div>
      <div className="home_container">
        <img src={img_3} alt=""/>
        <p>description 2</p>
      </div>
      <div className="home_container">
        <img src={img_2} alt=""/>
        <p>description 3</p>
      </div>
      <div className="home_2nd_part">
        <h2>Antenne de Sologne</h2>
        <div className="home_container">
          <img src={img_sologne} className="img_height_limit" alt=""/>
          <div>
            <p>
              Pour mettre en œuvre notre ambition de faire diminuer le harcèlement scolaire, nous
              utilisons nos vécus, nos expériences et surtout les conséquences que ce fléau a eu sur nous et nos
              proches.
            </p>
            <p>
              Pour mettre en œuvre notre ambition de faire diminuer le harcèlement scolaire, nous
              utilisons nos vécus, nos expériences et surtout les conséquences que ce fléau a eu sur nous et nos
              proches.
            </p>
            <p>
              Nous avons également depuis peu un bureau à Selles-sur-Cher et une antenne en Sologne
            </p>
            <p>
              Responsable : Madame Nolwen POTIN - <a
              href="mailto:nolwen@harcelement-scolaire-41.fr">nolwen@harcelement-scolaire-41.fr</a>
            </p>
          </div>
        </div>
      </div>
      <div>
        <h2>Selles-sur-Cher, Ville pilote</h2>
        <div className="home_container">
          <img src={img_sologne} className="img_height_limit" alt=""/>
          <div>
            <p></p>
            <p>
              Nous avons décidé de collaborer avec l’association LCLHSS41, l’association a décider de choisir la ville
              de Selles-sur-Cher comme ville pilote, du fait de ces infrastructures scolaires et de son investissement
              auprès des jeunes Sellois, il nous est apparu important voir primordial d’encourager une telle initiative.
            </p>
            <p>
              Nous avons également depuis peu un bureau à Selles-sur-Cher et une antenne en Sologne
            </p>
            <p>
              Maire de la Ville de Selles-sur-Cher : Stella COCHETON
            </p>
          </div>
        </div>
      </div>
      <div className="confiance">
        <h2>Ils nous font confiance</h2>
        <Hlist list={data}/>
      </div>
    </>
  )
}
